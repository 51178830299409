<template>
  <div class="d-flex align-center">
    <v-autocomplete
      v-if="!loading"
      v-model="selectedFornecedor"
      :label="$tc('global.fornecedor')"
      :items="fornecedores"
      :loading="buscandoFornecedores"
      :search-input.sync="searchFornecedor"
      dense
      outlined
      flat
      solo
      hide-details
      item-text="nome"
      item-value="id"
      :placeholder="$tc('global.buscarFornecedor')"
      append-icon=""
      full-width
      return-object
      no-filter
      :readonly="readonly"
      :clearable="!readonly"
      ><template v-slot:selection="data">
        <div class="pa-0">
          <div class="">{{ data.item.nome }}</div>
        </div>
      </template>
    </v-autocomplete>
    <div class="ml-4">
      <v-btn
        v-if="selectedFornecedor"
        height="38px"
        width="48px"
        color="#4CAF50"
        x-small
        @click="goToEditFornecedor"
      >
        <v-icon class="white--text">mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-else
        depressed
        height="38px"
        width="48px"
        color="#4CAF50"
        x-small
        @click="goToAddFornecedor"
      >
        <v-icon class="white--text">mdi-plus</v-icon>
      </v-btn>
    </div>
    <DialogFornecedor
      v-if="dialogFornecedor"
      :dialogFornecedor.sync="dialogFornecedor"
      :edit="edit"
      :fornecedor_id="selectedFornecedor ? selectedFornecedor.id : null"
      @fetch-fornecedores="buscaFornecedores"
    />
  </div>
</template>

<script>
import { searchFornecedoresCombobox } from "@/api/fornecedores/fornecedores.js";
export default {
  name: "SearchComboboxFornecedores",

  components: {
    DialogFornecedor: () => import("./DialogFornecedor.vue"),
  },

  props: {
    fornecedor_sync: {
      type: [Object, String],
    },
    fornecedor_id: {
      type: [Number, String],
      default: null,
    },
    fornecedor_nome: {
      type: [String],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    prestadores: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      selectedFornecedor: null,
      fornecedores: [],
      buscandoFornecedores: false,
      searchFornecedor: "",
      debounce: null,
      dialogFornecedor: false,
    };
  },

  watch: {
    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.$emit("update:fornecedor_sync", this.selectedFornecedor);
      } else {
        this.$emit("update:fornecedor_sync", null);
      }
    },
    searchFornecedor() {
      if (!this.searchFornecedor || this.buscandoFornecedores) return;
      this.buscaFornecedores();
    },
  },

  methods: {
    async searchFornecedoresCombobox(filtro) {
      this.buscandoFornecedores = true;

      await searchFornecedoresCombobox(filtro).then((response) => {
        this.fornecedores = response;
        if (this.fornecedor_id) {
          this.selectedFornecedor = this.fornecedores.find((item) => {
            return item.id == this.fornecedor_id;
          });
        }
      });
      this.buscandoFornecedores = false;
    },

    async buscaFornecedores(fornecedor_id) {
      let filtro = `?search=${this.searchFornecedor}`;
      if (this.prestadores){
        filtro += `&prestadores=1`;
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.searchFornecedoresCombobox(
          this.searchFornecedor ? filtro : ""
        );
        if (fornecedor_id) {
          this.selectedFornecedor = this.fornecedores.find((item) => {
            return item.id == fornecedor_id;
          });
        }
      }, 500);
    },

    goToAddFornecedor() {
      this.edit = false;
      this.dialogFornecedor = true;
    },

    goToEditFornecedor() {
      this.edit = true;
      this.dialogFornecedor = true;
    },
  },

  async mounted() {
    if (this.fornecedor_nome) {
      let url = `?search=${this.fornecedor_nome}`;
      if (this.prestadores) {
        url += `&prestadores=true`;
      }
      await this.searchFornecedoresCombobox(url);
    }
    this.loading = false;
  },
};
</script>

<style></style>
